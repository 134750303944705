import { Component } from 'react';

class ComponentBase extends Component {
	constructor(props) {
		super(props);

		this.classes = [];
	}

	removeClass(className, classes) {
		return [
			...classes.filter(cls => cls !== className ? cls : false)
		]
	}

	_classesResolve(classesPassed) {
		const { classes: globalClasses } = this;

		let classes = !classesPassed ? globalClasses : classesPassed;

		const classNames = (this.props.className ?? '').split(' ');

		return [...classes, ...classNames].join(' ');
	}

	classesResolve(classesPassed) {
		return this._classesResolve(classesPassed);
	}
}

export default ComponentBase;
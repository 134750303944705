import ComponentBase from './ComponentBase';

import Icon from './Icon';
import Link from 'next/link';

class Button extends ComponentBase {
  constructor(props) {
    super(props);

    this.size = [];

    this.size['normal'] = ['px-6', 'py-2'];

    this.size['sm'] = ['px-4', 'py-1', 'text-sm'];

    this.variant = [];

    this.variant['primary'] = [
      'bg-primary-500',
      'text-white',
      'hover:bg-primary-700',
    ];

    this.variant['neutral'] = [
      'bg-gray-200/50',
      'dark:bg-gray-700',
      'hover:bg-gray-200',
      'hover:dark:bg-gray-600',
      'text-gray-900',
      'dark:text-white',
      'border-gray-300/50',
      'dark:border-gray-600',
    ];

    this.variant['danger'] = ['bg-red-500', 'text-white', 'hover:bg-red-700'];
    this.variant['success'] = [
      'bg-green-500',
      'text-white',
      'hover:bg-green-700',
    ];

    this.variant['outline-danger'] = [
      'bg-red-100',
      'border-red-200',
      'bg-transparent',
      'text-red-500',
      'hover:bg-red-500',
      'hover:text-white',
      'hover:border-red-500',
    ];

    this.variant['outline-primary'] = [
      'bg-primary-100',
      'dark:bg-primary-800/50',
      'border-primary-300',
      'dark:border-primary-700/50',
      'text-primary-500',
      'dark:text-primary-400',
      'hover:bg-primary-500',
      'dark:hover:bg-primary-500',
      'dark:hover:text-primary-300',
      'hover:text-white',
      'hover:border-primary-500',
    ];

    this.classes = [
      'font-semibold',
      'transition',
      'duration-300',
      'rounded',
      'border',
      'border-transparent',
      'focus:border-primary-200',
      'disabled:opacity-75',
    ];
  }

  render() {
    const props = this.props;
    const { className = '', as = 'button' } = props;
    const { size = 'normal', variant = 'primary' } = props;
    const classess = [
      ...this.classes,
      ...className.split(' '),
      ...this.size[size],
      ...this.variant[variant],
    ];
    const classesResolve = this.classesResolve(classess);
    const btnState = props.state ?? 'normal';

    const Wrapper = as;

    /**
     * Disable button automatically when state is loading
     */
    const isDisabled =
      btnState.toLowerCase() == 'loading' || props.disabled ? true : false;

    return (
      <>
        {props.href ? (
          props.external ? (
            <a
              href={props.href}
              className={classesResolve}
              target={props.target}>
              {props.children}
            </a>
          ) : (
            <Link href={props.href} as={props.as}>
              <a className={classesResolve}>
                {btnState == 'normal' ? (
                  props.children
                ) : (
                  <Icon name="loader" width="24" className="anim-spin" />
                )}
              </a>
            </Link>
          )
        ) : (
          <Wrapper
            onClick={props.onClick}
            className={classesResolve}
            type={props.type ?? 'button'}
            disabled={isDisabled}>
            {btnState == 'normal' ? (
              props.children
            ) : (
              <Icon name="loader" width="24" className="anim-spin" />
            )}
          </Wrapper>
        )}
      </>
    );
  }
}

export default Button;
